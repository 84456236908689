<template>
	<div class="driveinfo">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试乘试驾邀请函分享"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
		</van-nav-bar>
		
		<img class="img_top" :src="serverUrl(invitationinfo.head_image)" mode="">
	
		<div class="driveinfo_top">
			{{invitationinfo.content[0]}}
			<div><span style="visibility: hidden;">空格</span> {{invitationinfo.content[1]}}</div>
		</div>
		
		<div class="driveinfo_mine" v-if="invitationinfo.store_service?.length > 0">
			<div class="driveinfo_mine_title">
				我的专属服务
			</div>
			<div class="driveinfo_mine_concat">
				专属服务项目可让产品专家在APP的“试乘试驾 > 我的 > 邀请”中设置。
			</div>
			<div @click="ischoos(v.id)" v-for="v,index in invitationinfo.store_service" :key="index" class="driveinfo_mine_list">
				<img v-if="form.service_ids?.indexOf(v.id) != -1" src="@/assets/drivroute/icon_xuandui.png" mode="">
				<img v-else src="@/assets/drivroute/icon_weixuanz.png" mode="">
				<span>{{v.name}}</span>
			</div>
		</div>
		
		<div class="driveinfo_shop">
			<img :src="serverUrl(invitationinfo.program.image)" mode="">
			<span>{{invitationinfo.program.name}}</span>
		</div>
		
		<div v-if="islat" class="driveinfo_map">
			<div class="driveinfo_map_left">
				<Map :center="lat"></Map>
			</div>
			<div class="driveinfo_map_rigth">
				<div class="driveinfo_map_title">
					{{invitationinfo.store_name}}
				</div>
				<div class="driveinfo_map_add">
					<img src="@/assets/drivroute/icon_dinwei.png" mode="">
					<span>{{invitationinfo.address}}</span>
				</div>
				<div class="driveinfo_map_photo">
					<img src="@/assets/drivroute/icon_model.png" mode="">
					<span>{{invitationinfo.contact}}</span>
				</div>
			</div>
		</div>
		
		<div class="driveinfo_footer">
			<van-image class="center_avatar" round :src="serverUrl(invitationinfo.avatar)" fit="cover"/>
			<span class="driveinfo_footer_name">{{invitationinfo.bio}}</span>
			<span class="driveinfo_footer_title">您的专属销售顾问{{invitationinfo.username}}随时为您服务</span>
		</div>
		
		<div class="user_footer">
			<div :class="{'isbackground':isred}" @click="save">
				{{isred == true?'已确认':'确认'}}
			</div>
		</div>
	</div>
</template>

<script>
import Map from '../../../components/map.vue'

export default {
	components: {
		Map
	},
	data(){
		return{
			scrollTop: 0,
			form:{service_ids:[]},
			invitationinfo:{},
			lat:[],
			id:'',
			isred:false,
			islat:true,
			isdiv:false,
		}
	},
	mounted(){
		this.id = this.$route.query.id
		this.getlicense()
	},
	methods:{
		// 发送邀请
		async save(){
			if(this.isred){
				return ''
			}
			const ids_list = this.form.service_ids?.join(',')
			console.log('111111111')
			const {data:res} = await this.$http.post('/api/scsj/h5_check_scsj_invitation',{id:this.id,service_ids:ids_list})
			if(res.code == 1){
				this.isred = true
				this.$toast.success('确认成功')
				this.getlicense()
			}
		},
		// 是否选中
		ischoos(id){
			if(this.isred) return ''
			const isid = this.form.service_ids.indexOf(id)
			if(isid == -1){
				this.form.service_ids.push(id)
			}else{
				this.form.service_ids.splice(isid,1)
			}
		},
		// 获取详情
		async getlicense(){
			const {data:res} = await this.$http.post('/api/scsj/h5_get_scsj_invitation_info_1_7_2',{id:this.id})
			if(res.code == 1){
				this.invitationinfo = res.data.res
				if(res.data.res.scsj_store_service_ids!=null){
					this.form.service_ids = res.data.res.scsj_store_service_ids.split(',')
					this.form.service_ids = this.form.service_ids?.map(item => parseInt(item))
				}
				console.log(this.form.service_ids)
				res.data.res.if_check == 1?this.isred = true:''
				if(this.invitationinfo?.lng_lat != null){
					console.log(this.invitationinfo.lng_lat)
					this.lat = this.invitationinfo.lng_lat.split(',')
					console.log('1111111')
				}else{
					this.islat = false
				} 
			}
			this.isdiv = true
		},
	}
}
</script>

<style lang="scss" scoped>
.driveinfo{
	.img_top{
		width: 750px;
	}
	.driveinfo_top{
		padding: 30px 32px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		display: flex;
		flex-direction: column;
		color: #FFFFFF;
		line-height: 48px;
	}
	.driveinfo_shop{
		border: 1px solid #979797;
		padding: 28px;
		margin: 60px 32px 0;
		display: flex;
		align-items: center;
		img{
			height: 144px;
			width: 144px;
		}
		span{
			margin-left: 40px;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
		}
	}
	.driveinfo_map{
		padding: 40px 32px;
		display: flex;
		.driveinfo_map_left{
			height: 242px;
			width: 242px;
			background-color: #fff;
		}
		.driveinfo_map_rigth{
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.driveinfo_map_title{
				
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #ACACAC;
				line-height: 40px;
			}
			.driveinfo_map_photo{
				display: flex;
				img{
					width: 32px;
					height: 28px;
				}
				span{
					margin-left: 20px;
					font-size: 32px;
					font-family: HYQiHeiY3-45;
					font-weight: normal;
					color: #ACACAC;
					line-height: 40px;
				}
			}
			.driveinfo_map_add{
				margin-top: 30px;
				display: flex;
				img{
					width: 32px;
					height: 28px;
				}
				span{
					width: 350px;
					margin-left: 20px;
					font-size: 32px;
					font-family: HYQiHeiY3-45;
					font-weight: normal;
					color: #ACACAC;
					line-height: 34px;
				}
			}
		}
		
	}
	.driveinfo_footer{
		padding: 40px;
		display: flex;
		align-items: center;
		flex-direction:column;
		.center_avatar{
			width: 120px;
			height: 120px;
			border: 1px solid #FA0037;
			border-radius: 50%;
		}
		.driveinfo_footer_name{
			margin-top: 32px;
			font-size: 40px;
			font-family: HYQiHeiY3-45;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 40px;
		}
		.driveinfo_footer_title{
			margin-top: 32px;
			font-size: 32px;
			color: #BFBFBF;
			line-height: 32px;
		}
	}
	.driveinfo_mine{
		margin: 20px 32px;
		.driveinfo_mine_title{
			font-size: 40px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
			padding: 20px 0;
			text-align: center;
		}
		.driveinfo_mine_concat{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #ACACAC;
		}
		.driveinfo_mine_list{
			margin-top: 30px;
			padding: 28px;
			display: flex;
			background: #222222;
			border-radius: 8px;
			img{
				height: 32px;
				width: 32px;
			}
			span{
				margin-left: 20px;
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
			}
		}
	}
}

.isbackground{
	background: #191919 !important;
}
.user_footer{
	width: 100%;
	height: 132px;
	background: #000000;
	padding: 20px;
	box-sizing: border-box;
	text-align: center;

	div{
		width: 670px;
		height: 92px;
		background: #FA0037;
		text-align: center;
		margin: 0 auto 0;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}
</style>